html {
  background-color: #fcf5f6;
}


/**********************
 Header-specific styles 
 **********************/

.App-header {
  text-align: center;
  color: black;
  min-height: 5vh;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  
  margin-bottom: 50px;
}

.author-name {
  margin-top: 25px;
  margin-bottom: 5px;
}
.author-subtitle {
  margin-top: 0;
  margin-bottom: 30px
}
.top-menu-navigation {
  display: inline-block;
}

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;

  margin-left: 12px;
  margin-right: 12px;
}

/********************
 Body-specific styles 
 ********************/

.App-body {
  text-align: left;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 100px;

  max-width: 780px;
  margin: 0 auto; /* centers the column */
}

.book-section {
  margin-top: 50px;
}

section {
  margin-bottom: 60px;
}

p {
  line-height: 1.75;
}

@media screen and (min-width: 600px) {
  .section-description {
    padding: 0px 50px;
  }

  .book-section {
    background-color: white;
    border-radius: 25px;

    padding: 15px 50px;
  }
}


/*********************
 General helper styles 
 *********************/

a {
  color: inherit;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}